import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import Layout from '../Layout'
import Section from 'components/Section/Section'

const TestFooterPage = () => {
  return (
    <A2ADataContextProvider>
      <Layout pageTitle="Test Footer Simple" footerType="simple">
        <>
          <Section backgroundColor={'#F6FBFA'} hasContainer={true}>
            <h2>Test Footer Simple</h2>
          </Section>
          <Section backgroundColor={'#F6FBFA'} hasContainer={true}></Section>
          <Section backgroundColor={'#F6FBFA'} hasContainer={true}></Section>
        </>
      </Layout>
    </A2ADataContextProvider>
  )
}

export default TestFooterPage
